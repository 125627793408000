// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { BookOpen, FileText, Headphones, Share2, Brain, Upload, Zap, Eye, Volume2 } from 'lucide-react';
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import Testimonials from '../components/Testimonials';


// const features = [
//   {
//     icon: <FileText className="w-6 h-6" />,
//     title: "Smart File Processing",
//     description: "Transform any document into digestible knowledge. PDFs, docs, or web articles - we handle it all."
//   },
//   {
//     icon: <Headphones className="w-6 h-6" />,
//     title: "Audio Magic",
//     description: "Turn lengthy lectures into concise summaries. Generate audio notes for on-the-go learning."
//   },
//   {
//     icon: <BookOpen className="w-6 h-6" />,
//     title: "Interactive Learning",
//     description: "Auto-generated quizzes and flashcards that adapt to your understanding."
//   },
//   {
//     icon: <Share2 className="w-6 h-6" />,
//     title: "Collaborative Growth",
//     description: "Share insights and study materials with your circle. Learn better together."
//   },
//   {
//     icon: <Brain className="w-6 h-6" />,
//     title: "Personal Learning AI",
//     description: "Your AI tutor that evolves with your learning style and pace."
//   }
// ];

// const highlights = [
//   {
//     icon: <Upload className="w-6 h-6" />,
//     title: "Smart Upload",
//     subtitle: "Any format, any source",
//     description: "Import your study materials in any format - PDFs, videos, audio, or documents. Our AI processes everything from academic papers to YouTube lectures."
//   },
//   {
//     icon: <Zap className="w-6 h-6" />,
//     title: "Instant Insights",
//     subtitle: "AI-powered understanding",
//     description: "Get immediate, deep understanding of your materials. Our AI breaks down complex topics and connects ideas across different sources."
//   },
//   {
//     icon: <Eye className="w-6 h-6" />,
//     title: "Source Tracking",
//     subtitle: "Clear citations, always",
//     description: "Every insight comes with precise citations. Jump directly to source materials and see exactly where information comes from."
//   },
//   {
//     icon: <Volume2 className="w-6 h-6" />,
//     title: "Audio Learning",
//     subtitle: "Learn anywhere, anytime",
//     description: "Transform any content into engaging audio summaries. Perfect for learning while commuting, exercising, or multitasking."
//   }
// ];

// const testimonials = [
//   {
//     text: "As a master's student, Edvance has been a game-changer for my thesis work. It's like having a personal research assistant available 24/7! Chatting with research articles and whole folders of content has saved me countless hours of reading and digging through papers.",
//     author: "Laurin. S",
//     role: "Masters Student",
//     avatar: "/laurin.jpg"
//   },
//   {
//     text: "I've been using Edvance for a while now and I must say how much I like it! It's incredibly helpful for summarizing key takeaways from research papers and long videos or podcasts, knowing from the start if the research is what I'm looking for and then allowing me to revisit important points precisely when I need them.",
//     author: "Dana S.",
//     role: "Economist",
//     avatar: "/dana.jpg"
//   },
//   {
//     text: "This is really nice. I don't have any regrets subscribing to Max version. The tool is perfect!!",
//     author: "Gracia",
//     role: "Researcher",
//     avatar: "/gracia.jpg"
//   },
//   {
//     text: "This update 🔥🔥🔥 I have a research lab meeting later today, and we're going to be discussing the implications of AI/tech. I used Edvance to generate a summary and ask a follow-up question & it absolutely delivered. The workflow was much more user-friendly than trying to accomplish the same thing in Bard.",
//     author: "Ben D.",
//     role: "Post-doc researcher",
//     avatar: "/ben.jpg"
//   },
//   {
//     text: "Truly loved interface: it's very straight forward and intuitive vs other ai's. Especially vs having 100 tabs open and copy & pasting back and forth from ChatGPT",
//     author: "Natalya Z",
//     role: "Pharmaceutical Researcher",
//     avatar: "/natalya.jpg"
//   },
//   {
//     text: "I took a 2 hour YouTube video and had the AI in Edvance summarise it. I then asked follow-up questions about the highlighted pieces that got my attention. All told, I was able to collect key takeaways in less than 10 mins. Amazing.",
//     author: "DnA",
//     role: "Researcher",
//     avatar: "/dna.jpg"
//   },
//   {
//     text: "This is the exact tool I've been looking for...I've gathered an overwhelming collection of over 700 bookmarks and 190 open web browser tabs. These resources are my roadmap to research, but navigating through them has become increasingly challenging.",
//     author: "Kirsten F.",
//     role: "ESG Reporting Analyst",
//     avatar: "/kirsten.jpg"
//   },
//   {
//     text: "Edvance is really an excellent tool for understanding my papers. It is just like my personal librarian for the internet!",
//     author: "Feng Chun",
//     role: "Neuroscience PhD",
//     avatar: "/feng.jpg"
//   },
//   {
//     text: "I don't know how you do it but the gap in summary and answer quality to ChatGPT is big. And having real text citations is a game changer.",
//     author: "Alex M.",
//     role: "Data Scientist",
//     avatar: "/alex.jpg"
//   }
// ];

// const scrollToHighlights = () => {
//   const highlightsSection = document.getElementById('highlights');
//   if (highlightsSection) {
//     highlightsSection.scrollIntoView({ behavior: 'smooth' });
//   }
// };

// const Home = () => {
//   const [currentFeature, setCurrentFeature] = useState(0);
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   useEffect(() => {
//     const featureTimer = setInterval(() => {
//       setCurrentFeature((prev) => (prev + 1) % features.length);
//     }, 3000);
    
//     const testimonialTimer = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 5000);

//     return () => {
//       clearInterval(featureTimer);
//       clearInterval(testimonialTimer);
//     };
//   }, []);

//   return (
//     <div className="flex flex-col min-h-screen bg-white">
//       <Header />
      
//       <main className="flex-grow flex flex-col justify-center items-center px-4 py-8 sm:py-12">
//         <div className="max-w-4xl text-center mb-8 sm:mb-16">
//           <div className="flex justify-center mb-6 sm:mb-8">
//             <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-emerald-50 border border-emerald-100 rounded-full shadow-sm">
//               <span className="text-xs sm:text-sm font-medium">✨ 🚀 Powered by GPT-4o, Claude 3.5</span>
//             </div>
//           </div>

//           <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight">
//             <span className="text-emerald-500 block mt-2">Transform Information</span>
//             Into Understanding
//           </h1>

//           <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 mb-8 sm:mb-12 text-lg sm:text-xl text-gray-700">
//             <p>1) Learn at your natural rhythm</p>
//             <p>2) Master concepts, not just facts</p>
//           </div>

//           <p className="text-lg sm:text-2xl text-gray-600 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4">
//             Everyone's learning style is unique. Let AI adapt to your style and help you grasp complex topics with ease.
//           </p>

//           <div className="mb-8 sm:mb-12">
//             <div className="flex justify-center -space-x-4 mb-4">
//               <img src="/avatar1.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar2.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar3.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar4.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar5.png" alt="Learner" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <div className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm bg-emerald-50 flex items-center justify-center text-emerald-600 text-sm sm:text-base font-medium">
//                 +3K
//               </div>
//             </div>
//             <p className="text-sm sm:text-base text-gray-500">Joining curious minds worldwide in smarter learning</p>
//           </div>

//           <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8 sm:mb-16 px-4">
//              <a 
//       href="https://testflight.apple.com/join/MUGceWJ3" 
//       target="_blank" 
//       rel="noopener noreferrer" 
//       className="block w-full sm:w-auto"
//     >
//       <button 
//         className="w-full sm:w-auto bg-emerald-500 text-white text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-600 transition duration-300 shadow-lg hover:shadow-xl"
//       >
//         Start Your Journey
//       </button>
//     </a>


//             <button  onClick={scrollToHighlights} className="w-full sm:w-auto bg-white text-emerald-600 text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-50 transition duration-300 border border-emerald-200">
//               See How It Works
//             </button>
//           </div>

//           <div className="flex flex-wrap justify-center gap-2 sm:gap-3 px-4">
//             {[
//               "🎯 Smart Summaries",
//               "🧠 Personalized Learning",
//               "💡 Concept Mapping",
//               "🎓 Interactive Lessons",
//               "🔄 Adaptive Learning",
//               "📊 Progress Insights"
//             ].map((feature, index) => (
//               <div key={index} className="bg-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300 text-sm sm:text-base">
//                 <span>{feature}</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </main>

     

//       <section id="highlights" className="py-12 sm:py-20 bg-gray-50">
//         <div className="max-w-6xl mx-auto px-4">
//           <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-16">
//             Powerful features, seamless experience
//           </h2>
//           <div className="grid md:grid-cols-2 gap-6 sm:gap-12">
//             {highlights.map((highlight, index) => (
//               <div key={index} className="bg-white rounded-xl p-6 sm:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
//                 <div className="w-10 h-10 sm:w-12 sm:h-12 bg-emerald-100 rounded-lg flex items-center justify-center text-emerald-600 mb-4 sm:mb-6">
//                   {highlight.icon}
//                 </div>
//                 <div className="space-y-2">
//                   <h3 className="text-xl sm:text-2xl font-semibold">{highlight.title}</h3>
//                   <p className="text-emerald-600 font-medium text-sm sm:text-base">{highlight.subtitle}</p>
//                   <p className="text-gray-600 mt-2 sm:mt-4 leading-relaxed text-sm sm:text-base">
//                     {highlight.description}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <Testimonials />

//       <div className="mt-8 sm:mt-16 text-center px-4">
//         <p className="text-xs sm:text-sm text-gray-400">
//           Trusted by students and professionals at
//         </p>
//         <div className="flex flex-wrap gap-4 sm:gap-8 mt-3 sm:mt-4 justify-center text-gray-400">
//           {["Harvard", "Stanford", "MIT", "Google"].map((org, index) => (
//             <span key={index} className="font-semibold text-sm sm:text-base">{org}</span>
//           ))}
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// };
// export default Home;

// //v2
// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { BookOpen, FileText, Headphones, Share2, Brain, Upload, Zap, Eye, Volume2 } from 'lucide-react';
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import Testimonials from '../components/Testimonials';

// const features = [
//   {
//     icon: <FileText className="w-6 h-6" />,
//     title: "Intelligent Document Analysis",
//     description: "Transform complex documents, reports, and industry research into actionable insights in minutes."
//   },
//   {
//     icon: <Headphones className="w-6 h-6" />,
//     title: "Audio Intelligence",
//     description: "Convert long-form content into precise briefings. Generate personalized audio summaries for efficient consumption."
//   },
//   {
//     icon: <BookOpen className="w-6 h-6" />,
//     title: "Dynamic Knowledge Base",
//     description: "Build your personal knowledge repository that evolves with your professional needs."
//   },
//   {
//     icon: <Share2 className="w-6 h-6" />,
//     title: "Team Intelligence",
//     description: "Share insights across your organization. Transform collective knowledge into competitive advantage."
//   },
//   {
//     icon: <Brain className="w-6 h-6" />,
//     title: "Adaptive AI Assistant",
//     description: "Your personal AI analyst that learns your priorities and decision-making patterns."
//   }
// ];

// const highlights = [
//   {
//     icon: <Upload className="w-6 h-6" />,
//     title: "Universal Input",
//     subtitle: "Any format, anywhere",
//     description: "Process everything from financial reports to industry research papers. Our AI handles PDFs, spreadsheets, presentations, and multimedia content with precision."
//   },
//   {
//     icon: <Zap className="w-6 h-6" />,
//     title: "Rapid Analysis",
//     subtitle: "AI-powered decision support",
//     description: "Get immediate strategic insights from complex data. Our AI identifies key trends, risks, and opportunities across multiple sources."
//   },
//   {
//     icon: <Eye className="w-6 h-6" />,
//     title: "Intelligence Tracking",
//     subtitle: "Complete audit trail",
//     description: "Every insight is traceable to source materials with precise citations. Maintain accountability and verify information instantly."
//   },
//   {
//     icon: <Volume2 className="w-6 h-6" />,
//     title: "Mobile Intelligence",
//     subtitle: "Insights anywhere",
//     description: "Transform dense content into mobile-friendly formats. Perfect for busy professionals who need insights while traveling or between meetings."
//   }
// ];

// const testimonials = [
//   {
//     text: "This tool has transformed how I handle market research. I can process hundreds of pages of reports in minutes and extract exactly what matters for our strategy decisions.",
//     author: "Alex M.",
//     role: "Strategy Director",
//     avatar: "/alex.jpg"
//   },
//   {
//     text: "The mobile experience is exceptional. I can digest complex research papers during my commute and immediately share key insights with my team.",
//     author: "Dana S.",
//     role: "Investment Analyst",
//     avatar: "/dana.jpg"
//   },
//   {
//     text: "Worth every penny for the time it saves. The AI's ability to connect insights across multiple sources has given us a genuine competitive edge.",
//     author: "Ben D.",
//     role: "Management Consultant",
//     avatar: "/ben.jpg"
//   },
//   {
//     text: "This has revolutionized how I handle industry reports and client documentation. The mobile interface makes complex data actually manageable.",
//     author: "Kirsten F.",
//     role: "Senior Analyst",
//     avatar: "/kirsten.jpg"
//   },
//   {
//     text: "The depth of analysis and connection-making across documents is remarkable. Essential for anyone dealing with large volumes of professional content.",
//     author: "Natalya Z",
//     role: "Research Director",
//     avatar: "/natalya.jpg"
//   },
//   {
//     text: "I've tried every AI tool out there - this is the only one that truly understands the needs of professional knowledge workers. The mobile-first approach is brilliant.",
//     author: "Feng C.",
//     role: "Management Consultant",
//     avatar: "/feng.jpg"
//   }
// ];

// const scrollToHighlights = () => {
//   const highlightsSection = document.getElementById('highlights');
//   if (highlightsSection) {
//     highlightsSection.scrollIntoView({ behavior: 'smooth' });
//   }
// };

// const Home = () => {
//   const [currentFeature, setCurrentFeature] = useState(0);
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);

//   useEffect(() => {
//     const featureTimer = setInterval(() => {
//       setCurrentFeature((prev) => (prev + 1) % features.length);
//     }, 3000);
    
//     const testimonialTimer = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 5000);

//     return () => {
//       clearInterval(featureTimer);
//       clearInterval(testimonialTimer);
//     };
//   }, []);

//   return (
//     <div className="flex flex-col min-h-screen bg-white">
//       <Header />
      
//       <main className="flex-grow flex flex-col justify-center items-center px-4 py-8 sm:py-12">
//         <div className="max-w-4xl text-center mb-8 sm:mb-16">
//           <div className="flex justify-center mb-6 sm:mb-8">
//             <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-emerald-50 border border-emerald-100 rounded-full shadow-sm">
//               <span className="text-xs sm:text-sm font-medium">🚀 Powered by Enterprise-Grade AI</span>
//             </div>
//           </div>

//           <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight">
//             <span className="text-emerald-500 block mt-2">Transform Complex Data</span>
//             Into Clear Decisions
//           </h1>

//           <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 mb-8 sm:mb-12 text-lg sm:text-xl text-gray-700">
//             <p>1) Process information at scale</p>
//             <p>2) Extract actionable insights</p>
//           </div>

//           <p className="text-lg sm:text-2xl text-gray-600 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4">
//             Your mobile AI analyst that transforms complex content into actionable intelligence, available whenever and wherever you make decisions.
//           </p>

//           <div className="mb-8 sm:mb-12">
//             <div className="flex justify-center -space-x-4 mb-4">
//               <img src="/avatar1.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar2.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar3.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar4.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <img src="/avatar5.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
//               <div className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm bg-emerald-50 flex items-center justify-center text-emerald-600 text-sm sm:text-base font-medium">
//                 +5K
//               </div>
//             </div>
//             <p className="text-sm sm:text-base text-gray-500">Trusted by leading professionals worldwide</p>
//           </div>

//           <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8 sm:mb-16 px-4">


//             <a 
//               href="https://testflight.apple.com/join/MUGceWJ3" 
//               target="_blank" 
//               rel="noopener noreferrer" 
//               className="block w-full sm:w-auto"
//             >
//               <button 
//                 className="w-full sm:w-auto bg-emerald-500 text-white text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-600 transition duration-300 shadow-lg hover:shadow-xl"
//               >
//                 Start Free Trial
//               </button>
//             </a>


//             <button onClick={scrollToHighlights} className="w-full sm:w-auto bg-white text-emerald-600 text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-50 transition duration-300 border border-emerald-200">
//               See How It Works
//             </button>
//           </div>

//           <div className="flex flex-wrap justify-center gap-2 sm:gap-3 px-4">
//             {[
//               "📊 Instant Analysis",
//               "🎯 Strategic Insights",
//               "🔄 Real-time Updates",
//               "📱 Mobile-First",
//               "🔒 Enterprise Security",
//               "🤝 Team Collaboration"
//             ].map((feature, index) => (
//               <div key={index} className="bg-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300 text-sm sm:text-base">
//                 <span>{feature}</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </main>

//       <section id="highlights" className="py-12 sm:py-20 bg-gray-50">
//         <div className="max-w-6xl mx-auto px-4">
//           <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-16">
//             Enterprise-grade intelligence, seamless experience
//           </h2>
//           <div className="grid md:grid-cols-2 gap-6 sm:gap-12">
//             {highlights.map((highlight, index) => (
//               <div key={index} className="bg-white rounded-xl p-6 sm:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
//                 <div className="w-10 h-10 sm:w-12 sm:h-12 bg-emerald-100 rounded-lg flex items-center justify-center text-emerald-600 mb-4 sm:mb-6">
//                   {highlight.icon}
//                 </div>
//                 <div className="space-y-2">
//                   <h3 className="text-xl sm:text-2xl font-semibold">{highlight.title}</h3>
//                   <p className="text-emerald-600 font-medium text-sm sm:text-base">{highlight.subtitle}</p>
//                   <p className="text-gray-600 mt-2 sm:mt-4 leading-relaxed text-sm sm:text-base">
//                     {highlight.description}
//                   </p>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <Testimonials />

//       <div className="mt-8 sm:mt-16 text-center px-4">
//         <p className="text-xs sm:text-sm text-gray-400">
//           Trusted by professionals at
//         </p>
//         <div className="flex flex-wrap gap-4 sm:gap-8 mt-3 sm:mt-4 justify-center text-gray-400">
//           {["McKinsey", "Goldman Sachs", "BCG", "Morgan Stanley"].map((org, index) => (
//             <span key={index} className="font-semibold text-sm sm:text-base">{org}</span>
//           ))}
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// };


// export default Home;

//v3

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { BookOpen, FileText, Headphones, Share2, Brain, Upload, Zap, Eye, Volume2, MessageSquare } from 'lucide-react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Testimonials from '../components/Testimonials';

const features = [
  {
    icon: <FileText className="w-6 h-6" />,
    title: "Smart Document Processing",
    description: "Process PDFs and documents on mobile. AI extracts key insights and creates smart knowledge pockets."
  },
  {
    icon: <MessageSquare className="w-6 h-6" />,
    title: "Chat Your Content",
    description: "Ask questions about your documents and get instant, sourced answers from your knowledge base."
  },
  {
    icon: <Headphones className="w-6 h-6" />,
    title: "AI Audio Summaries",
    description: "Listen to AI-generated summaries of your documents while commuting or multitasking."
  },
  {
    icon: <Brain className="w-6 h-6" />,
    title: "Deep Analysis",
    description: "AI analyzes trends, identifies key findings, and generates actionable insights from your content."
  }
];

const highlights = [
  {
    icon: <Upload className="w-6 h-6" />,
    title: "Smart Processing",
    subtitle: "AI-powered understanding",
    description: "Upload any document and let AI create smart knowledge pockets. Extract insights, summaries, and action items automatically."
  },
  {
    icon: <MessageSquare className="w-6 h-6" />,
    title: "Interactive Chat",
    subtitle: "Chat with your content",
    description: "Have natural conversations with your documents. Ask questions and get instant, accurate answers with source citations."
  },
  {
    icon: <Headphones className="w-6 h-6" />,
    title: "Audio Intelligence",
    subtitle: "Listen on the go",
    description: "AI transforms your documents into engaging audio summaries. Perfect for commutes or multitasking. Capture voice notes instantly."
  },
  {
    icon: <Brain className="w-6 h-6" />,
    title: "AI Analysis",
    subtitle: "Actionable insights",
    description: "Get AI-powered analysis of trends, patterns, and key findings. Turn complex documents into clear action items."
  }
];

const testimonials = [
  {
    text: "The AI chat is incredible - I can instantly find answers from my documents without endless scrolling. The audio summaries save me hours every week.",
    author: "Alex M.",
    role: "Management Consultant",
    avatar: "/alex.jpg"
  },
  {
    text: "Being able to chat with my research papers and get instant insights has transformed how I work. The mobile experience is exceptional.",
    author: "Dana S.",
    role: "Research Analyst",
    avatar: "/dana.jpg"
  },
  {
    text: "The AI analysis is spot-on. It pulls out exactly what I need and generates actionable takeaways. Audio summaries during commute are a game-changer.",
    author: "Ben D.",
    role: "Strategy Consultant",
    avatar: "/ben.jpg"
  },
  {
    text: "Smart knowledge pockets have revolutionized how I handle industry reports. I can find anything instantly and the AI insights save me countless hours.",
    author: "Kirsten F.",
    role: "Investment Analyst",
    avatar: "/kirsten.jpg"
  }
];

const scrollToHighlights = () => {
  const highlightsSection = document.getElementById('highlights');
  if (highlightsSection) {
    highlightsSection.scrollIntoView({ behavior: 'smooth' });
  }
};

const Home = () => {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const featureTimer = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % features.length);
    }, 3000);
    
    const testimonialTimer = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => {
      clearInterval(featureTimer);
      clearInterval(testimonialTimer);
    };
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      
      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8 sm:py-12">
        <div className="max-w-4xl text-center mb-8 sm:mb-16">
          <div className="flex justify-center mb-6 sm:mb-8">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 bg-emerald-50 border border-emerald-100 rounded-full shadow-sm">
              <span className="text-xs sm:text-sm font-medium">🤖 Powered by GPT-4 & Claude</span>
            </div>
          </div>

          <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-6 sm:mb-8 leading-tight tracking-tight">
            <span className="text-emerald-500 block mt-2">Transform Information Into</span>
             Instant Actionable Insights
          </h1>

          <div className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-8 mb-8 sm:mb-12 text-lg sm:text-xl text-gray-700">
            <p>Process content instantly</p>
            <p>Get AI-powered analysis</p>
          </div>

          <p className="text-lg sm:text-2xl text-gray-600 mb-8 sm:mb-12 max-w-2xl mx-auto leading-relaxed px-4">
            Turn documents into smart knowledge pockets. Chat with your content, get audio summaries, and extract actionable insights on mobile.
          </p>

          <div className="mb-8 sm:mb-12">
            <div className="flex justify-center -space-x-4 mb-4">
              <img src="/avatar1.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar2.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <img src="/avatar3.png" alt="Professional" className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm" />
              <div className="w-8 h-8 sm:w-12 sm:h-12 rounded-full border-2 sm:border-4 border-white shadow-sm bg-emerald-50 flex items-center justify-center text-emerald-600 text-sm sm:text-base font-medium">
                +500
              </div>
            </div>
            <p className="text-sm sm:text-base text-gray-500">Professionals using AI to work smarter</p>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center mb-8 sm:mb-16 px-4">
            <a 
              href="https://testflight.apple.com/join/MUGceWJ3" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block w-full sm:w-auto"
            >
              <button 
                className="w-full sm:w-auto bg-emerald-500 text-white text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-600 transition duration-300 shadow-lg hover:shadow-xl"
              >
                Download Beta
              </button>
            </a>

            <button onClick={scrollToHighlights} className="w-full sm:w-auto bg-white text-emerald-600 text-base sm:text-lg px-6 sm:px-8 py-3 sm:py-4 rounded-xl hover:bg-emerald-50 transition duration-300 border border-emerald-200">
              See How It Works
            </button>
          </div>

          <div className="flex flex-wrap justify-center gap-2 sm:gap-3 px-4">
            {[
              "💬 Chat with documents",
              "🎧 AI audio summaries",
              "🧠 Smart analysis",
              "📱 Mobile-first",
              "⚡️ Instant answers",
              "📊 Action items"
            ].map((feature, index) => (
              <div key={index} className="bg-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-xl border border-gray-100 flex items-center gap-2 hover:border-emerald-200 transition-colors duration-300 text-sm sm:text-base">
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </div>
      </main>

      <section id="highlights" className="py-12 sm:py-20 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-16">
            AI-powered insights in your pocket
          </h2>
          <div className="grid md:grid-cols-2 gap-6 sm:gap-12">
            {highlights.map((highlight, index) => (
              <div key={index} className="bg-white rounded-xl p-6 sm:p-8 shadow-sm hover:shadow-md transition-shadow duration-300">
                <div className="w-10 h-10 sm:w-12 sm:h-12 bg-emerald-100 rounded-lg flex items-center justify-center text-emerald-600 mb-4 sm:mb-6">
                  {highlight.icon}
                </div>
                <div className="space-y-2">
                  <h3 className="text-xl sm:text-2xl font-semibold">{highlight.title}</h3>
                  <p className="text-emerald-600 font-medium text-sm sm:text-base">{highlight.subtitle}</p>
                  <p className="text-gray-600 mt-2 sm:mt-4 leading-relaxed text-sm sm:text-base">
                    {highlight.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Testimonials />

      <div className="mt-8 sm:mt-16 text-center px-4">
        <p className="text-xs sm:text-sm text-gray-400">
          Early users from
        </p>
        <div className="flex flex-wrap gap-4 sm:gap-8 mt-3 sm:mt-4 justify-center text-gray-400">
          {["McKinsey", "BCG", "Deloitte", "Goldman"].map((org, index) => (
            <span key={index} className="font-semibold text-sm sm:text-base">{org}</span>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;